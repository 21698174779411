<template>
    <vue-cal
        ref="vuecal"
        class="calendar_width"
        :hide-title-bar="true"
        :hide-view-selector="true"
        :active-view="'week'"
        :locale="'ko'"
        :time-step="120"
        :time-from="0"
        :time-to="24 * 60"
        :events="events"
        :editable-events="{ drag: false }"
        :time-cell-height="49"
        :disable-views="['years', 'year', 'month', 'day']"
        :on-event-click="onEventClick"
        @cell-click="cellClick"
    >
        <template #weekday-heading="{ heading }">
            <span class="font_white">{{ heading.label }}</span>
        </template>

        <template #time-cell="{ hours }">
            <div class="calendar_time_cell_wrap">
                <span class="calendar_time_cell">{{ getHour(hours) }}시</span>
            </div>
        </template>

        <template #event>
            <div class="vuecal__event-title" />
        </template>
    </vue-cal>
</template>
<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import { defineComponent } from '@vue/composition-api';
import { cloneDeep } from 'lodash-es';
export default defineComponent({
  name: 'CalendarComp',
  emits: ['opencuration-detail', 'opencuration-default'],
  props: {
    curationtimelist: {
      type: Array,
      default: () => {
        return [];
      },
      required: false
    }
  },
  components: {
    VueCal
  },
  created () {},
  mounted () {
    this.setEvents();
  },
  data () {
    return {
      events: []
    };
  },
  methods: {
    setEvents () {
      const dayTextList = ['월', '화', '수', '목', '금', '토', '일'];
      const cloneTimeList = cloneDeep(this.curationtimelist) || [];
      if (this.$refs.vuecal.viewCells) {
        const len = cloneTimeList.length;
        for (let i = 0; i < len; i++) {
          const { day, startTimeDate, endTImeDate, curationId } = cloneTimeList[i];
          if (day) {
            const daylist = day.split(',');
            const daylistlen = daylist.length;
            for (let dayIndex = 0; dayIndex < daylistlen; dayIndex++) {
              const findIndex = dayTextList.findIndex(item => item.trim() === daylist[dayIndex].trim());
              if (findIndex > -1) {
                const { formattedDate } = this.$refs.vuecal.viewCells[findIndex];
                let formattedEndDate = formattedDate;
                const startSplit = startTimeDate.split(':');
                const endSplit = endTImeDate.split(':');

                const startTime = parseInt(startSplit[0]) * 60 + parseInt(startSplit[1]);
                const endTime = parseInt(endSplit[0]) * 60 + parseInt(endSplit[1]);
                if (startTime > endTime) {
                  const afterOneDay = new Date(formattedDate).getDate() + 1;
                  const dateEnd = new Date(new Date().setDate(afterOneDay));
                  const endYyyy = dateEnd.getFullYear();
                  const endMonth = dateEnd.getMonth() + 1 > 9 ? dateEnd.getMonth() + 1 : `0${dateEnd.getMonth() + 1}`;
                  const endDate = dateEnd.getDate() > 9 ? dateEnd.getDate() : `0${dateEnd.getDate()}`;

                  formattedEndDate = `${endYyyy}-${endMonth}-${endDate}`;
                }
                const start = `${formattedDate} ${startTimeDate}`;
                const end = `${formattedEndDate} ${endTImeDate}`;

                const event = {
                  start,
                  end,
                  title: '',
                  content: '',
                  class: cloneTimeList[i].colorcss,
                  curationId
                };

                this.events.push(event);
              }
            }
          }
        }
      }
    },
    getHour (val) {
      if (val > 23) {
        return val - 24 > 9 ? `오후 ${val - 24}` : `오전 ${val - 24}`;
      }

      if (val > 12) {
        return `오후 ${val - 12}`;
      }
      return val > 9 ? `오후 ${val}` : `오전 ${val}`;
    },
    onEventClick (evt) {
      const { curationId } = evt;
      const find = this.curationtimelist.find(item => item.curationId === curationId);
      this.$emit('opencuration-detail', find);
    },
    cellClick () {
      const find = this.curationtimelist.find(item => {
        if (!item.startTimeDate && !item.endTImeDate) {
          return true;
        }

        return false;
      });
      this.$emit('opencuration-default', {
        ...find
      });
    }
  }
});
</script>
<style>
.vuecal__body,
.vuecal__flex,
.vuecal__bg {
  overflow: visible;
}
.vuecal__no-event {
  display: none;
}
.vuecal__flex.vuecal__cells.week-view {
  background-color: var(--434343) !important;
}
.vuecal__cell--today,
.vuecal__cell--selected {
  background-color: transparent;
  z-index: 0 !important;
}
.vuecal__cell--selected {
  background-color: transparent;
  z-index: 0 !important;
}
.vuecal__weekdays-headings {
  border: 1px transparent;
}
.vuecal__now-line {
  color: var(--white);
  border-top: 2px solid;
  opacity: 1;
  z-index: 99;
}

.vuecal__time-cell::before {
  border-top: 1px solid rgba(196, 196, 196, 0.3);
  content: '';
  position: absolute;
  right: 0;
  width: 93%;
  height: 1px;
  display: inline-block;
}

.vuecal__now-line:before {
  border: 5px solid var(--white);
  border-radius: 50%;
  left: -5px;
}

.vuecal__event--focus {
  box-shadow: none !important;
  z-index: 0 !important;
}

.vuecal__event {
  outline: none;
  margin: 0 1.2px;
}
.vuecal__event.first {
  background-color: var(--timecurationfirst);
  box-sizing: border-box;
  z-index: 0;
}
.vuecal__event.second {
  background-color: var(--timecurationsecond);
  border: 1px solid var(--timecurationsecond);
  z-index: 0;
}
.vuecal__event.third {
  background-color: var(--timecurationthird);
  border: 1px solid var(--timecurationthird);
  z-index: 0;
}
.vuecal__event.four {
  background-color: var(--timecurationfour);
  border: 1px solid var(--timecurationfour);
  z-index: 0;
}

.vuecal__event.five {
  background-color: var(--timecurationfive);
  border: 1px solid var(--timecurationfive);
  z-index: 0;
}

.vuecal__event.six {
  background-color: var(--timecurationsix);
  border: 1px solid var(--timecurationsix);
  z-index: 0;
}

.vuecal__time-column {
  width: 5em;
}

.vuecal__time-column .vuecal__time-cell {
  text-align: left;
  position: static;
}

.vuecal--view-with-time .vuecal__weekdays-headings {
  padding-left: 5em;
}
@media all and (max-width: 768px) {
  .vuecal__time-cell::before {
    width: 81%;
  }
}
</style>
<style scoped>
.calendar_width {
  max-width: 940px;
  width: 100%;
}

.calendar_height {
  height: 576px;
}
.calendar_time_cell_wrap {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}

.calendar_time_cell {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--FFFFFFA3);
  position: absolute;
  top: -10px;
  left: -5px;
  display: inline-block;
  width: 60px;
  text-align: right;
}
</style>
